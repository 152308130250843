import React from "react";
import { useHistory } from "react-router-dom";
import ContentSectionWithBottomActions from "~components/styled/contentSection/ContentSectionWithBottomActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import ErrorIcon from "~assets/ilustrations/illus_solic_error.svg";

const GenericError = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <ContentSectionWithBottomActions
      actions={
        <ContentSection>
          <CallToActionButton
            variant="outlined"
            color="secondary"
            label={"Volver al Inicio"}
            fullWidth={false}
            marginBottom={12}
            onClick={() => {
              history.push("/");
            }}
          />
        </ContentSection>
      }
    >
      <div className={classes.content}>
        <img src={ErrorIcon} className={classes.img} />
        <Typography className={classes.title}>Hubo un error</Typography>
        <Typography className={classes.text}>
          Por favor, volvé a intentarlo en un momento. Si el problema persiste,
          contactate con soporte para que podamos solucionarlo.
        </Typography>
      </div>
    </ContentSectionWithBottomActions>
  );
};

export default GenericError;

const useStyles = makeStyles((theme) => ({
  img: {
    width: pxToRem(242.5),
    marginBottom: pxToRem(22),
    alignItems: "center",
  },
  title: {
    fontSize: pxToRem(28),
    color: theme.palette.primary.main,
    marginBottom: pxToRem(12),
    lineHeight: 1.21,
    fontWeight: "bold",
    textAlign: "center",
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161",
  },
  content: {
    paddingTop: pxToRem(60),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
