import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useDispatch } from "react-redux";
import {
  setFechaVencimientoTarjeta,
  clearFechaVencimientoTarjeta,
} from "../actions";

import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import DatePicker from "~styled/datePicker/DatePickerTarjeta";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    width: "100%",
    maxWidth: 500,
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
  buttonContinuar: {
    paddingTop: 24,
  },
}));

const FechaVencimientoTarjetaStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [isValidDate, setIsValidDate] = useState(false);

  useEffect(() => {
    dispatch(clearFechaVencimientoTarjeta());
  }, [dispatch]);

  const formatDate = (date) => {
    if (!date || isNaN(new Date(date).getTime())) return null;
    const formattedMonth = String(new Date(date).getMonth() + 1).padStart(
      2,
      "0"
    );
    const formattedYear = String(new Date(date).getFullYear()).slice(-2);
    return `${formattedMonth}/${formattedYear}`;
  };

  const checkDateValidity = (date) => {
    if (!date || isNaN(new Date(date).getTime())) return false;

    const selectedDate = new Date(date);
    const currentDate = new Date();
    currentDate.setDate(1);

    return selectedDate > currentDate;
  };

  const onDateChange = (date) => {
    setValue(date);
    setIsValidDate(checkDateValidity(date));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && isValidDate) {
      const formattedValue = formatDate(value);
      dispatch(
        setFechaVencimientoTarjeta({ value: formattedValue, selected: true })
      );
    }
  };

  return (
    <div className={classes.cotizador}>
      <div className={classes.title}>
        <PrimaryTitle
          label="¿En qué fecha vence tu tarjeta?"
          marginBottom={48}
        />
      </div>

      <DatePicker
        fullWidth
        onChange={onDateChange}
        value={value}
        onKeyDown={onKeyDown}
        error={!isValidDate && value !== null}
        helperText={
          !isValidDate && value !== null
            ? "La fecha debe ser mayor al mes y año actual"
            : ""
        }
      />
      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!isValidDate}
          label={"Continuar"}
          onClick={() => {
            const formattedValue = formatDate(value);
            dispatch(
              setFechaVencimientoTarjeta({
                value: formattedValue,
                selected: true,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default FechaVencimientoTarjetaStep;
