import React, { useRef } from "react";
import pxToRem from "~libs/pxToRem";
import Portal from "@material-ui/core/Portal";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles(() => ({
  actions: {
    bottom: 0,
    padding: "16px",
    paddingTop: "10px",
    paddingBottom: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 220px)",
    marginLeft: "200px",
    position: "relative",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      flexDirection: "row",
      padding: "12px",
    },
    "@media (min-width: 425px) and (max-width: 768px)": {
      width: "100%",
      marginLeft: "0px",
      flexDirection: "column",
      paddingLeft: "220px",
    },
    "@media (max-width: 430px)": {
      width: "100%",
      marginLeft: "0px",
      flexDirection: "column",
      paddingLeft: "8px",
      padding: "8px",
    },
  },
}));

const ContentSectionWithBottomActions = ({ children, actions }) => {
  const classes = useStyles();
  const myRef = useRef();

  return (
    <>
      <section
        style={{
          width: "100%",
          maxWidth: pxToRem(500),
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {children}
      </section>
      {actions && (
        <Portal>
          <div
            ref={myRef}
            className={classes.actions}
            style={{
              maxWidth: "100%",
            }}
          >
            {actions}
          </div>
        </Portal>
      )}
    </>
  );
};

export default ContentSectionWithBottomActions;
