const isCreditCardNumberValid = (number) => {
  if (number.length < 13 || number.length > 19) return false;

  let suma = 0;

  let ultimoDigito = number.substr(number.length - 1);

  number
    .substr(0, number.length - 1)
    .split("")
    .reverse()
    .forEach((stringDigit, index) => {
      let posicionImpar = (index + 1) % 2 !== 0;
      let digit = parseInt(stringDigit);
      suma += !posicionImpar
        ? digit
        : digit * 2 < 10
          ? digit * 2
          : digit * 2 - 9;
      return suma;
    });

  let digitoVerificador = 10 - ((suma % 10) % 10);

  if (digitoVerificador === 10) digitoVerificador = 0;

  let esNumeroValido = parseInt(ultimoDigito) === parseInt(digitoVerificador);

  return esNumeroValido;
};

const getCreditCardType = (accountNumber) => {
  if (/^5[1-5]/.test(accountNumber)) return "MasterCard";
  if (/^4/.test(accountNumber)) return "Visa";
  if (/^3[47]/.test(accountNumber)) return "AmericanExpress";
  if (/^36/.test(accountNumber)) return "Diners";

  return null;
};

export { isCreditCardNumberValid, getCreditCardType };
