import { useHistory, useParams } from "react-router-dom";
import useLocationRef from "~hooks/useLocationRef";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const steps = {
  agenciaStep: "0",
  marcaStep: "1",
  anioStep: "2",
  modeloStep: "3",
  provinciaStep: "4",
  localidadStep: "5",
  codigoPostalStep: "6",
  usoStep: "7",
  condicionFiscalStep: "8",
  tieneGNCStep: "9",
  formaDePagoStep: "10",
  sendPostCotizacionesStep: "11",
  listadoCotizacionesStep: "12",
  detallePolizaStep: "13",
  dniStep: "14",
  sexoStep: "15",
  esPersonaValidaStep: "16",
  nombreStep: "17",
  fechaNacimientoStep: "18",
  cuilStep: "19",
  direccionStep: "20",
  emailStep: "21",
  telefonoStep: "22",

  dominioStep: "23",
  motorStep: "24",
  numeroChasisStep: "25",

  fotoFrenteVehiculoStep: "26",
  fotoTraseraVehiculoStep: "27",
  fotoLateralIzquierdoVehiculoStep: "28",
  fotoLateralDerechoVehiculoStep: "29",

  datosPagoStep: "30",
  marcaTarjetaCreditoStep: "31",
  fechaVencimientoTarjetaStep: "32",

  fechaVigenciaDesdeStep: "33",
  sendPostSolicitudEmisionStep: "34",
  finalStep: "35",
};

export const stages = {
  initialStage: steps.agenciaStep,
  recotizar: steps.sendPostCotizacionesStep,
  continuarSolicitud: steps.dniStep,
};

const BASE_URL = "/riesgos/automotores/multicotizador";

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.multicotizadorReducer,
  }));

export const useCurrentStep = () => {
  const history = useHistory();

  const locationRef = useLocationRef();

  const currentState = useReduxSelector();
  const [firstStep, validSteps] = getValidSteps(currentState);

  const { position = firstStep } = useParams();

  const isValidStep = validSteps.find((x) => x.index === position)
    ? true
    : false;

  const currentStep = validSteps.slice().pop();

  useEffect(() => {
    const stepUrl = `${BASE_URL}`;
    if (!isValidStep) {
      history.replace(stepUrl);
    }
  }, [history, isValidStep]);

  useEffect(() => {
    const stepUrl = `${BASE_URL}/${currentStep.index}`;
    const isThisUrl = locationRef.current.pathname === stepUrl;
    const isFirstStep =
      locationRef.current.pathname === `${BASE_URL}` &&
      currentStep.index === firstStep;

    if (!isThisUrl && !isFirstStep && isValidStep) history.push(stepUrl);

    //eslint-disable-next-line
  }, [isValidStep, history, currentStep.index]);

  return [position, currentState];
};

export const getValidSteps = ({
  agenciaSelected,
  marcaSelected,
  anioSelected,
  modeloSelected,
  provinciaSelected,
  localidadSelected,
  codigoPostalSelected,
  usoSelected,
  condicionFiscalSelected,
  tieneGNC,
  formaDePagoSelected,
  sendPostCotizacion,
  currentPoliza,
  dniSelected,
  sexoSelected,
  esPersonaValida,
  fechaNacimientoSelected,
  nombreSelected,
  cuilSelected,
  direccionSelected,
  telefonoSelected,
  emailSelected,
  dominioSelected,
  motorSelected,
  numeroChasisSelected,
  multicotizacionInitialStage,
  validadorDatosPagoSelected,
  marcaTarjetaCreditoSelected,
  tarjetaDeMercantil,
  fotoFrenteVehiculoSelected,
  fotoTraseraVehiculoSelected,
  fotoLateralIzquierdoVehiculoSelected,
  fotoLateralDerechoVehiculoSelected,
  fechaVencimientoTarjetaSelected,
  fechaVigenciaDesdeSelected,
  isFotosVehiculoEnabled = true, //ver si agregar flag
  sendPostSolicitudEmision,
}) => {
  const agenciaStep = step(steps.agenciaStep, !agenciaSelected.selected);
  const marcaStep = step(steps.marcaStep, !marcaSelected.value);
  const anioStep = step(steps.anioStep, !anioSelected.value);
  const modeloStep = step(steps.modeloStep, !modeloSelected.value);
  const provinciaStep = step(steps.provinciaStep, !provinciaSelected);
  const localidadStep = step(
    steps.localidadStep,
    !localidadSelected && multicotizacionInitialStage === stages.initialStage
  );
  const codigoPostalStep = step(steps.codigoPostalStep, !codigoPostalSelected);
  const usoStep = step(steps.usoStep, !usoSelected);
  const condicionFiscalStep = step(
    steps.condicionFiscalStep,
    !condicionFiscalSelected && usoSelected && usoSelected.value === "Comercial"
  );
  const tieneGncStep = step(steps.tieneGNCStep, !tieneGNC.selected);
  const formaDePagoStep = step(
    steps.formaDePagoStep,
    !formaDePagoSelected.value
  );

  const sendPostCotizacionesStep = step(
    steps.sendPostCotizacionesStep,
    sendPostCotizacion
  );
  const listadoCotizacionesStep = step(
    steps.listadoCotizacionesStep,
    !currentPoliza.open
  );

  const detallePolizaStep = step(
    steps.detallePolizaStep,
    !currentPoliza.contratada
  );
  const dniStep = step(steps.dniStep, !dniSelected);
  const sexoStep = step(steps.sexoStep, !sexoSelected.selected);
  const esPersonaValidaStep = step(
    steps.esPersonaValidaStep,
    !esPersonaValida.selected
  );
  const fechaNacimientoStep = step(
    steps.fechaNacimientoStep,
    !fechaNacimientoSelected.selected
  );
  const nombreStep = step(steps.nombreStep, !nombreSelected);
  const cuilStep = step(steps.cuilStep, !cuilSelected.selected);
  const direccionStep = step(steps.direccionStep, !direccionSelected);
  const telefonoStep = step(steps.telefonoStep, !telefonoSelected.value);
  const emailStep = step(steps.emailStep, !emailSelected.selected);
  const dominioStep = step(steps.dominioStep, !dominioSelected.selected);
  const motorStep = step(steps.motorStep, !motorSelected.selected);
  const numeroChasisStep = step(
    steps.numeroChasisStep,
    !numeroChasisSelected.selected
  );

  const datosPagoStep = step(
    steps.datosPagoStep,
    !validadorDatosPagoSelected.selected &&
    (formaDePagoSelected.value === "DebitoConCBU" ||
      formaDePagoSelected.value === "DebitoConTarjetaCredito")
  );

  const marcaTarjetaCreditoStep = step(
    steps.marcaTarjetaCreditoStep,
    !marcaTarjetaCreditoSelected.selected &&
    formaDePagoSelected.value === "DebitoConTarjetaCredito"
  );

  const fechaVencimientoTarjetaStep = step(
    steps.fechaVencimientoTarjetaStep,
    !fechaVencimientoTarjetaSelected.selected &&
    tarjetaDeMercantil.value &&
    formaDePagoSelected.value === "DebitoConTarjetaCredito"
  );

  const fotoFrenteVehiculoStep = step(
    steps.fotoFrenteVehiculoStep,
    !fotoFrenteVehiculoSelected.selected &&
    isFotosVehiculoEnabled &&
    currentPoliza.value
  );
  const fotoTraseraVehiculoStep = step(
    steps.fotoTraseraVehiculoStep,
    !fotoTraseraVehiculoSelected.selected &&
    isFotosVehiculoEnabled &&
    currentPoliza.value
  );
  const fotoLateralIzquierdoVehiculoStep = step(
    steps.fotoLateralIzquierdoVehiculoStep,
    !fotoLateralIzquierdoVehiculoSelected.selected &&
    isFotosVehiculoEnabled &&
    currentPoliza.value
  );
  const fotoLateralDerechoVehiculoStep = step(
    steps.fotoLateralDerechoVehiculoStep,
    !fotoLateralDerechoVehiculoSelected.selected &&
    isFotosVehiculoEnabled &&
    currentPoliza.value
  );

  const postSolicitudStep = step(
    steps.sendPostSolicitudEmisionStep,
    sendPostSolicitudEmision
  );

  const fechaVigenciaDesdeStep = step(
    steps.fechaVigenciaDesdeStep,
    !fechaVigenciaDesdeSelected.selected
  );
  const finalStep = step(steps.finalStep, true);

  //set order of steps
  agenciaStep
    .next(marcaStep)
    .next(anioStep)
    .next(modeloStep)
    .next(provinciaStep)
    .next(localidadStep)
    .next(codigoPostalStep)
    .next(usoStep)
    .next(condicionFiscalStep)
    .next(tieneGncStep)
    .next(formaDePagoStep)
    .next(sendPostCotizacionesStep)
    .next(listadoCotizacionesStep)
    .next(detallePolizaStep)

    .next(dniStep)
    .next(sexoStep)
    .next(esPersonaValidaStep)
    .next(nombreStep)
    .next(fechaNacimientoStep)
    .next(cuilStep)
    .next(direccionStep)
    .next(telefonoStep)
    .next(emailStep)
    .next(dominioStep)
    .next(numeroChasisStep)
    .next(motorStep)
    .next(fotoFrenteVehiculoStep)
    .next(fotoTraseraVehiculoStep)
    .next(fotoLateralIzquierdoVehiculoStep)
    .next(fotoLateralDerechoVehiculoStep)
    .next(datosPagoStep)
    .next(marcaTarjetaCreditoStep)
    .next(fechaVencimientoTarjetaStep)
    .next(fechaVigenciaDesdeStep)
    .next(postSolicitudStep)
    .next(finalStep);

  if (multicotizacionInitialStage === stages.recotizar) {
    return [steps.listadoCotizacionesStep, listadoCotizacionesStep.run()];
  }
  if (multicotizacionInitialStage === stages.continuarSolicitud) {
    return [steps.dniStep, dniStep.run()];
  }
  return [steps.agenciaStep, agenciaStep.run()];
};

const step = (index, isThisStep) => {
  let next = null;
  let order = 1;

  return {
    index,
    order,
    next(step) {
      if (step) next = { ...step, order: this.order + 1 };
      return next;
    },
    run() {
      return isThisStep ? [this] : [this, ...(next && next.run())];
    },
  };
};
