import React, { memo, useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Next from "@material-ui/icons/ArrowForward";
import Star from "@material-ui/icons/Star";
import ContentLoader from "react-content-loader";
import InfiniteScroll from "react-infinite-scroller";
import { ListSubheader } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import pxToRem from "~libs/pxToRem";

interface StyleProps {
  roundedTop?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  listPaper: (props) => ({
    borderRadius: props.roundedTop ? "8px 8px 8px 8px" : "0px 0px 8px 8px",
    boxShadow: "0 0 4px 0 #7a6e8e3c",
  }),
  subheader: {
    position: "inherit",
    color: "#424242",
    fontSize: pxToRem(14),
    fontWeight: "bold",
  },
  root: {
    minHeight: 30,
    maxHeight: "calc(100vh - 220px);",
    overflow: "auto",
    width: "100%",
  },
  iconButton: {
    color: theme.palette.primary.main,
    padding: 0,
  },
  textItem: {
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.15px",
    textAlign: "left",
    fontSize: pxToRem(14),
    fontWeight: 500,
    color: "#212121",
    "&:hover": {
      backgroundColor: "white !important",
      color: `${theme.palette.primary.main} !important`,
    },
  },
  selectedItem: {
    backgroundColor: "#f4f1fa !important",
    color: `${theme.palette.primary.main} !important`,
  },
}));

interface ListViewProps {
  list: { label: string; value: string | null }[];
  labelMostUsed?: string;
  labelTodas?: string;
  IconMostUsed?: React.ComponentType<any>; // Si necesitas tipar más específicamente, puedes usar `SvgIconProps`
  mostUsedList?: { label: string; value: string | null }[];
  onItemSelected: (item: { label: string; value: string | null }) => void;
  isLoading?: boolean;
  roundedTop?: boolean;
  usePagination?: boolean;
}

const ListView: React.FC<ListViewProps> = memo(
  ({
    list,
    labelMostUsed = "FRECUENTES",
    labelTodas = "TODAS",
    IconMostUsed = Star,
    mostUsedList,
    onItemSelected,
    isLoading,
    roundedTop,
    usePagination = true,
  }) => {
    const [items, setItems] = useState(list);
    const [hasMore, setHasMore] = useState(true);

    const classes = useStyles({ roundedTop });

    const listRef = useRef(null);

    useEffect(() => {
      const nextPage = getPage(list, 1);
      setItems(nextPage);
      setHasMore(usePagination);
    }, [list, usePagination]);

    const loadMore = (page: number) => {
      const nextPage = getPage(list, page);

      const uniqueItems = nextPage.filter(
        (item) =>
          !items.some((existingItem) => existingItem.value === item.value)
      );

      setItems([...items, ...uniqueItems]);
      if (usePagination) setHasMore(uniqueItems.length > 0);
    };

    return (
      <Paper className={classes.listPaper}>
        <List innerRef={listRef} className={classes.root} disablePadding>
          {isLoading && <LoadingTemplate length={5} />}
          {!isLoading && list.length > 0 && (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={<LoadingTemplate key={"loading-infinite"} length={1} />}
              useWindow={false}
              getScrollParent={() => listRef.current}
            >
              {mostUsedList && mostUsedList.length > 0 && (
                <>
                  <ListSubheader className={classes.subheader}>
                    {labelMostUsed}
                  </ListSubheader>
                  <Divider />
                  {mostUsedList.map((item, i) => (
                    <ListViewItem
                      key={`lisViewItem-${item.value}${i}`}
                      value={item.value}
                      label={item.label}
                      onItemSelected={onItemSelected}
                      Icon={IconMostUsed}
                    />
                  ))}
                  <ListSubheader className={classes.subheader}>
                    {labelTodas}
                  </ListSubheader>
                </>
              )}
              <Divider />
              {items.map(
                (item, i) =>
                  item.label && (
                    <ListViewItem
                      key={`lisViewItem-${item.value}${i}`}
                      value={item.value}
                      label={item.label}
                      onItemSelected={onItemSelected}
                      isLastItem={i === items.length - 1}
                      Icon={Next}
                    />
                  )
              )}
            </InfiniteScroll>
          )}
          {!isLoading && list.length === 0 && (
            <ListItem>
              <ListItemText
                className={classes.textItem}
                primary="No hay resultados."
                disableTypography
              />
            </ListItem>
          )}
        </List>
      </Paper>
    );
  }
);

export default ListView;

interface ListViewItemProps {
  value: string | null;
  label: string;
  onItemSelected: (item: { label: string; value: string | null }) => void;
  isLastItem?: boolean;
  Icon: React.ComponentType<any>;
}

const ListViewItem: React.FC<ListViewItemProps> = memo(
  ({ value, label, onItemSelected, isLastItem, Icon }) => {
    const classes = useStyles({});

    return (
      <ListItem
        button
        divider={!isLastItem}
        classes={{
          root: classes.textItem,
          focusVisible: classes.selectedItem,
        }}
        onClick={() => onItemSelected({ value, label })}
      >
        <ListItemText primary={label} disableTypography />
        <IconButton className={classes.iconButton} disabled={true}>
          <Icon />
        </IconButton>
      </ListItem>
    );
  }
);

const LoadingTemplate: React.FC<{ length: number }> = ({ length }) => {
  const classes = useStyles({});
  return (
    <>
      {Array.from({ length }).map((_, index) => (
        <ListItem key={`listViewLoadingItem-${index}`} divider>
          <ListItemText
            primary={
              <ContentLoader
                height={10}
                width={316}
                speed={1}
                backgroundColor="#f7f8ff"
                foregroundColor="#d9e8df"
              >
                <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
                <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
                <rect x="190" y="0" rx="3" ry="3" width="10" height="10" />
              </ContentLoader>
            }
            disableTypography
          />
          <IconButton className={classes.iconButton} disabled={true}>
            {<Next />}
          </IconButton>
        </ListItem>
      ))}
    </>
  );
};

const getPage = (
  list: { label: string; value: string | null }[],
  page: number
) => {
  const pageCount = 30;
  const begin = (page - 1) * pageCount;
  const end = page * pageCount > list.length ? list.length : page * pageCount;

  return list.length > 0 ? list.slice(begin, end) : [];
};
