import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";
import ItemLoadingCard from "./ItemLoadingCard";
import { useQuery } from "react-query";
import datosPago from "~api/datosPago";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DatosPagoDialog from "~styled/dialogs/DatosPagoDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
    height: "1rem",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
    paddingLeft: "0.5%",
  },
}));

const useGetDatosPago = (id) => {
  const response = useQuery(
    id,
    async (id) => {
      try {
        return await datosPago().getInformacionDatosPago(id);
      } catch (err) {
        console.error(err);
      }
    },
    { staleTime: 1000 * 60 * 15, enabled: id } //15 min cache
  );

  return {
    isLoadingDatosPago: response.isLoading,
    datosPago: response?.data,
  };
};

const ItemDatosPagoCard = ({
  label,
  onChange,
  readOnly,
  isLoading,
  idDatosPago,
  size = "small",
}) => {
  const classes = useStyles();
  const [openDatosPago, setOpenDatosPago] = useState(false);

  const { isLoadingDatosPago, datosPago } = useGetDatosPago(idDatosPago);

  if (isLoading || isLoadingDatosPago)
    return <ItemLoadingCard label={label} size="small" />;

  const datosPagoValue = datosPago?.numeroTarjeta ?? datosPago?.numeroCBU;
  const datosPagoValueHidden = `************ ${datosPagoValue?.slice(
    datosPagoValue.length - 4,
    datosPagoValue.length
  )}`;
  const showDatosPago = true;
  return (
    <div
      className={classes.container}
      style={size === "small" ? { padding: "1%" } : {}}
    >
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {datosPagoValue && (
          <Typography className={classes.value}>
            {datosPagoValueHidden}
          </Typography>
        )}

        {showDatosPago && (
          <Tooltip title={"Mostrar"}>
            <IconButton
              className={classes.iconButton}
              onClick={() => setOpenDatosPago(true)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )}

        <DatosPagoDialog
          isLoading={!datosPago && openDatosPago}
          openDialog={openDatosPago}
          onClose={() => {
            setOpenDatosPago(false);
          }}
          datosPago={{ datosPago: datosPagoValue }}
          formaPago={datosPago?.formaPago}
          marcaTC={datosPago?.marcaTC}
        />
      </div>
    </div>
  );
};

export default ItemDatosPagoCard;
