import { createActions, handleActions } from "redux-actions";
import fileService from "~libs/fileService";
import { stages } from "./useCurrentStep";

const {
  multicotizador: {
    clear,
    clearAgencia,
    setAgencia,
    clearVehiculoMarca,
    setVehiculoMarca,
    clearVehiculoAnio,
    setVehiculoAnio,
    clearVehiculoModelo,
    setVehiculoModelo,
    clearProvincia,
    setProvincia,
    clearLocalidad,
    setLocalidad,
    clearCodigoPostal,
    setCodigoPostal,
    clearVehiculoUso,
    setVehiculoUso,
    clearCondicionFiscal,
    setCondicionFiscal,
    clearTieneGNC,
    setTieneGNC,
    clearFormaDePago,
    setFormaDePago,
    clearDni,
    setDni,
    clearSexo,
    setSexo,
    clearEsPersonaValida,
    setEsPersonaValida,
    clearFechaNacimiento,
    setFechaNacimiento,
    clearNombre,
    setNombre,
    clearApellido,
    setApellido,
    clearCuil,
    setCuil,
    clearDireccion,
    setDireccion,
    clearTelefono,
    setTelefono,
    clearEmail,
    setEmail,
    clearDominio,
    setDominio,
    clearMotor,
    setMotor,
    clearNumeroChasis,
    setNumeroChasis,
    clearFotoFrenteVehiculo,
    setFotoFrenteVehiculo,
    clearFotoTraseraVehiculo,
    setFotoTraseraVehiculo,
    clearOmitirCargaFotos,
    setOmitirCargaFotos,
    clearFotoLateralIzquierdoVehiculo,
    setFotoLateralIzquierdoVehiculo,
    clearFotoLateralDerechoVehiculo,
    setFotoLateralDerechoVehiculo,
    clearValidadorDatosPago,
    setValidadorDatosPago,
    clearMarcaTarjetaCredito,
    setMarcaTarjetaCredito,
    setTarjetaDeMercantil,
    clearTarjetaDeMercantil,
    setFechaVencimientoTarjeta,
    clearFechaVencimientoTarjeta,
    setInitialCurrentPoliza,
    clearCurrentPoliza,
    setCurrentPoliza,
    clearContratarCurrentPoliza,
    setContratarCurrentPoliza,
    clearVigenciaDesde,
    setVigenciaDesde,

    //continuarCotizacion
    postRecotizarInited,
    postRecotizarCompleted,
    fetchRecotizarFailed,
    //continuarCotizacion
    fetchContinuarSolicitudInited,
    fetchContinuarSolicitudCompleted,
    fetchContinuarSolicitudFailed,
    //fetchIdentidad
    fetchIdentidadInited,
    fetchIdentidadCompleted,
    fetchIdentidadFailed,
    //postPedidoCotizacion
    postPedidoCotizacionInited,
    postPedidoCotizacionCompleted,
    postPedidoCotizacionFailed,
    //postDatosContacto
    postDatosContactoInited,
    postDatosContactoCompleted,
    postDatosContactoFailed,
    //getCotizacionDetalle
    getDetalleCotizacionInited,
    getDetalleCotizacionCompleted,
    getDetalleCotizacionFailed,
  },
} = createActions({
  multicotizador: {
    clear: () => ({}),
    clearAgencia: () => ({}),
    setAgencia: (value) => ({ value }),
    clearVehiculoMarca: () => ({}),
    setVehiculoMarca: (value) => ({ value }),
    clearVehiculoAnio: () => ({}),
    setVehiculoAnio: (value) => ({ value }),
    clearVehiculoModelo: () => ({}),
    setVehiculoModelo: (value) => ({ value }),
    clearProvincia: () => ({}),
    setProvincia: (value) => ({ value }),
    clearLocalidad: () => ({}),
    setLocalidad: (value) => ({ value }),
    clearCodigoPostal: () => ({}),
    setCodigoPostal: (value) => ({ value }),
    clearVehiculoUso: () => ({}),
    setVehiculoUso: (value) => ({ value }),
    clearCondicionFiscal: () => ({}),
    setCondicionFiscal: (value) => ({ value }),
    clearTieneGNC: () => ({}),
    setTieneGNC: (value) => ({ value }),
    clearFormaDePago: () => ({}),
    setFormaDePago: (value) => ({ value }),
    clearNombre: () => ({}),
    setNombre: (value) => ({ value }),
    clearApellido: () => ({}),
    setApellido: (value) => ({ value }),
    clearCuil: () => ({}),
    setCuil: (value) => ({ value }),
    clearDireccion: () => ({}),
    setDireccion: (value) => ({ value }),
    clearTelefono: () => ({}),
    setTelefono: (value) => ({ value }),
    clearEmail: () => ({}),
    setEmail: (value) => ({ value }),
    clearDominio: () => ({}),
    setDominio: (value) => ({ value }),
    clearMotor: () => ({}),
    setMotor: (value) => ({ value }),
    clearNumeroChasis: () => ({}),
    setNumeroChasis: (value) => ({ value }),
    clearOmitirCargaFotos: () => ({}),
    setOmitirCargaFotos: (value) => ({ value }),
    clearFotoFrenteVehiculo: () => ({}),
    setFotoFrenteVehiculo: (value) => ({ value }),
    clearFotoTraseraVehiculo: () => ({}),
    setFotoTraseraVehiculo: (value) => ({ value }),
    clearFotoLateralIzquierdoVehiculo: () => ({}),
    setFotoLateralIzquierdoVehiculo: (value) => ({ value }),
    clearFotoLateralDerechoVehiculo: () => ({}),
    setFotoLateralDerechoVehiculo: (value) => ({ value }),
    clearValidadorDatosPago: () => ({}),
    setValidadorDatosPago: (value) => ({ value }),
    clearMarcaTarjetaCredito: () => ({}),
    setMarcaTarjetaCredito: (value) => ({ value }),
    clearTarjetaDeMercantil: () => ({}),
    setTarjetaDeMercantil: (value) => ({ value }),
    clearFechaVencimientoTarjeta: () => ({}),
    setFechaVencimientoTarjeta: (value) => ({ value }),

    //pasos pendientes a agregar
    clearDni: () => ({}),
    setDni: (value) => ({ value }),
    clearSexo: () => ({}),
    setSexo: (value) => ({ value }),
    clearEsPersonaValida: () => ({}),
    setEsPersonaValida: (value) => ({ value }),
    clearFechaNacimiento: () => ({}),
    setFechaNacimiento: (value) => ({ value }),

    setInitialCurrentPoliza: (value) => ({ value }),
    clearCurrentPoliza: () => ({}),
    setCurrentPoliza: (value) => ({ value }),
    clearContratarCurrentPoliza: () => ({}),
    setContratarCurrentPoliza: (value) => ({ value }),
    clearVigenciaDesde: () => ({}),
    setVigenciaDesde: (value) => ({ value }),

    setDetalleCotizacion: (value) => ({ value }),
    //
    postRecotizarInited: () => ({}),
    postRecotizarCompleted: (response) => ({ response }),
    fetchRecotizarFailed: () => ({}),
    //
    fetchContinuarSolicitudInited: () => ({}),
    fetchContinuarSolicitudCompleted: (response) => ({ response }),
    fetchContinuarSolicitudFailed: () => ({}),
    //
    fetchIdentidadInited: () => ({}),
    fetchIdentidadCompleted: (response) => ({ response }),
    fetchIdentidadFailed: (error) => ({ error }),
    //
    postPedidoCotizacionInited: () => ({}),
    postPedidoCotizacionCompleted: (response) => ({ response }),
    postPedidoCotizacionFailed: (error) => ({ error }),
    //
    postDatosContactoInited: () => ({}),
    postDatosContactoCompleted: (response) => ({ response }),
    postDatosContactoFailed: (error) => ({ error }),
    //
    getDetalleCotizacionInited: () => ({}),
    getDetalleCotizacionCompleted: (response) => {
      let detalleCotizacion = null;

      if (
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0
      ) {
        detalleCotizacion = response;
      }

      return { detalleCotizacion };
    },
    getDetalleCotizacionFailed: (error) => ({ error }),
  },
});

const initialState = {
  isLoading: false,
  cotizacionId: null,
  detalleCotizacion: null,
  polizasRequestCount: 0,
  multicotizacionInitialStage: stages.initialStage,
  cotizacionARecotizar: null,

  currentPoliza: { value: null, open: false, contratada: false },

  agenciaSelected: { value: null, label: null, selected: false },
  marcaSelected: { value: null, label: null },
  modeloSelected: { value: null, label: null },
  anioSelected: { value: null, label: null },
  provinciaSelected: null,
  localidadSelected: null,
  codigoPostalSelected: null,
  usoSelected: null,
  condicionFiscalSelected: null,
  tieneGNC: { value: null, selected: false },
  formaDePagoSelected: { value: null, label: null },
  dniSelected: null,
  sexoSelected: { value: null, selected: false },
  fechaNacimientoSelected: { value: null, selected: false },
  cuilSelected: { value: null, selected: false },
  nombreSelected: null,
  apellidoSelected: null,
  telefonoSelected: { value: null, isMobile: true },
  esPersonaValida: { value: null, selected: false },
  emailSelected: { value: null, selected: false },
  direccionSelected: null,
  dominioSelected: { value: null, selected: false },
  motorSelected: { value: null, selected: false },
  numeroChasisSelected: { value: null, selected: false },
  validadorDatosPagoSelected: { value: null, selected: false },
  marcaTarjetaCreditoSelected: { value: null, selected: false },
  tarjetaDeMercantil: { value: false },
  fotoFrenteVehiculoSelected: { value: null, selected: false },
  fotoTraseraVehiculoSelected: { value: null, selected: false },
  fotoLateralIzquierdoVehiculoSelected: { value: null, selected: false },
  fotoLateralDerechoVehiculoSelected: { value: null, selected: false },
  fechaVigenciaDesdeSelected: { value: null, selected: false },
  fechaVencimientoTarjetaSelected: { value: null, selected: false },
  sendPostCotizacion: true,
  sendPostSolicitudEmision: true,
};

export const multicotizadorReducer = handleActions(
  {
    [clear]: () => {
      return {
        ...initialState,
        isForward: true,
      };
    },
    [clearAgencia]: (state, action) => {
      return {
        ...state,
        agenciaSelected: { value: null, label: null, selected: false },
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearVehiculoMarca]: (state, action) => {
      return {
        ...state,
        modeloSelected: { value: null, label: null },
        anioSelected: { value: null, label: null },
        marcaSelected: { value: null, label: null },
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearVehiculoAnio]: (state, action) => {
      return {
        ...state,
        modeloSelected: { value: null, label: null },
        anioSelected: { value: null, label: null },
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearVehiculoModelo]: (state, action) => {
      return {
        ...state,
        modeloSelected: { value: null, label: null },
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearProvincia]: (state, action) => {
      return {
        ...state,
        provinciaSelected: null,
        localidadSelected: null,
        codigoPostalSelected: null,
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearLocalidad]: (state, action) => {
      return {
        ...state,
        localidadSelected: null,
        codigoPostalSelected: null,
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearCodigoPostal]: (state, action) => {
      return {
        ...state,
        codigoPostalSelected: null,
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearVehiculoUso]: (state, action) => {
      return {
        ...state,
        usoSelected: null,
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearCondicionFiscal]: (state, action) => {
      return {
        ...state,
        condicionFiscalSelected: null,
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearTieneGNC]: (state, action) => {
      return {
        ...state,
        tieneGNC: { value: null, selected: false },
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearFormaDePago]: (state, action) => {
      return {
        ...state,
        formaDePagoSelected: { value: null, label: null },
        cotizacionId: null,
        sendPostCotizacion: true,
      };
    },
    [clearDni]: (state, action) => {
      return {
        ...state,
        dniSelected: null,
        sexoSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearEsPersonaValida]: (state, action) => {
      return {
        ...state,
        esPersonaValida: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearSexo]: (state, action) => {
      return {
        ...state,
        sexoSelected: { value: null, selected: false },
        esPersonaValida: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearFechaNacimiento]: (state, action) => {
      return {
        ...state,
        fechaNacimientoSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearNombre]: (state, action) => {
      return {
        ...state,
        nombreSelected: null,
        sendPostSolicitudEmision: true,
      };
    },
    [clearApellido]: (state, action) => {
      return {
        ...state,
        apellidoSelected: null,
        sendPostSolicitudEmision: true,
      };
    },
    [clearCuil]: (state, action) => {
      return {
        ...state,
        cuilSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearDireccion]: (state, action) => {
      return {
        ...state,
        direccionSelected: null,
        sendPostSolicitudEmision: true,
      };
    },
    [clearTelefono]: (state, action) => {
      return {
        ...state,
        telefonoSelected: { value: null, isMobile: true },
        sendPostSolicitudEmision: true,
      };
    },
    [clearEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearMotor]: (state, action) => {
      return {
        ...state,
        motorSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearNumeroChasis]: (state, action) => {
      return {
        ...state,
        numeroChasisSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearValidadorDatosPago]: (state, action) => {
      return {
        ...state,
        validadorDatosPagoSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearMarcaTarjetaCredito]: (state, action) => {
      return {
        ...state,
        marcaTarjetaCreditoSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearTarjetaDeMercantil]: (state, action) => {
      return {
        ...state,
        tarjetaDeMercantil: { value: false },
      };
    },
    [clearFechaVencimientoTarjeta]: (state, action) => {
      return {
        ...state,
        fechaVencimientoTarjetaSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },
    [clearOmitirCargaFotos]: (state, action) => {
      return {
        ...state,
        fotoFrenteVehiculoSelected: {
          ...state.fotoFrenteVehiculoSelected,
          selected: false,
        },
        fotoTraseraVehiculoSelected: {
          ...state.fotoTraseraVehiculoSelected,
          selected: false,
        },
        fotoLateralIzquierdoVehiculoSelected: {
          ...state.fotoLateralIzquierdoVehiculoSelected,
          selected: false,
        },
        fotoLateralDerechoVehiculoSelected: {
          ...state.fotoLateralDerechoVehiculoSelected,
          selected: false,
        },
      };
    },
    [clearFotoFrenteVehiculo]: (state, action) => {
      return {
        ...state,
        fotoFrenteVehiculoSelected: {
          ...state.fotoFrenteVehiculoSelected,
          selected: false,
        },
      };
    },
    [clearFotoTraseraVehiculo]: (state, action) => {
      return {
        ...state,
        fotoTraseraVehiculoSelected: {
          ...state.fotoTraseraVehiculoSelected,
          selected: false,
        },
      };
    },
    [clearFotoLateralIzquierdoVehiculo]: (state, action) => {
      return {
        ...state,
        fotoLateralIzquierdoVehiculoSelected: {
          ...state.fotoLateralIzquierdoVehiculoSelected,
          selected: false,
        },
      };
    },
    [clearFotoLateralDerechoVehiculo]: (state, action) => {
      return {
        ...state,
        fotoLateralDerechoVehiculoSelected: {
          ...state.fotoLateralDerechoVehiculoSelected,
          selected: false,
        },
      };
    },
    [clearVigenciaDesde]: (state, action) => {
      return {
        ...state,
        fechaVigenciaDesdeSelected: { value: null, selected: false },
        sendPostSolicitudEmision: true,
      };
    },

    [clearCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: {
          value: state.currentPoliza.value,
          open: false,
          contratada: false,
        },
      };
    },
    [clearContratarCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: {
          value: state.currentPoliza.value,
          open: true,
          contratada: false,
        },
      };
    },

    [setAgencia]: (state, action) => {
      return {
        ...state,
        agenciaSelected: action.payload.value,
      };
    },
    [setVehiculoMarca]: (state, action) => {
      return {
        ...state,
        marcaSelected: action.payload.value,
      };
    },
    [setVehiculoAnio]: (state, action) => {
      return {
        ...state,
        anioSelected: action.payload.value,
      };
    },
    [setVehiculoModelo]: (state, action) => {
      return {
        ...state,
        modeloSelected: action.payload.value,
      };
    },
    [setProvincia]: (state, action) => {
      return {
        ...state,
        provinciaSelected: action.payload.value,
      };
    },
    [setLocalidad]: (state, action) => {
      return {
        ...state,
        localidadSelected: action.payload.value,
      };
    },
    [setCodigoPostal]: (state, action) => {
      return {
        ...state,
        codigoPostalSelected: action.payload.value,
      };
    },
    [setVehiculoUso]: (state, action) => {
      return {
        ...state,
        usoSelected: action.payload.value,
      };
    },
    [setCondicionFiscal]: (state, action) => {
      return {
        ...state,
        condicionFiscalSelected: action.payload.value,
      };
    },
    [setTieneGNC]: (state, action) => {
      return {
        ...state,
        tieneGNC: action.payload.value,
      };
    },
    [setFormaDePago]: (state, action) => {
      return {
        ...state,
        formaDePagoSelected: action.payload.value,
      };
    },
    [setDni]: (state, action) => {
      return {
        ...state,
        dniSelected: action.payload.value,
      };
    },
    [setSexo]: (state, action) => {
      return {
        ...state,
        sexoSelected: action.payload.value,
      };
    },
    [setEsPersonaValida]: (state, action) => {
      return {
        ...state,
        esPersonaValida: action.payload.value,
      };
    },
    [setFechaNacimiento]: (state, action) => {
      return {
        ...state,
        fechaNacimientoSelected: action.payload.value,
      };
    },
    [setNombre]: (state, action) => {
      return {
        ...state,
        nombreSelected: action.payload.value,
      };
    },
    [setApellido]: (state, action) => {
      return {
        ...state,
        apellidoSelected: action.payload.value,
      };
    },
    [setCuil]: (state, action) => {
      return {
        ...state,
        cuilSelected: action.payload.value,
      };
    },
    [setDireccion]: (state, action) => {
      return {
        ...state,
        direccionSelected: action.payload.value,
      };
    },
    [setTelefono]: (state, action) => {
      return {
        ...state,
        telefonoSelected: action.payload.value,
      };
    },
    [setEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: action.payload.value,
      };
    },
    [setDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: action.payload.value,
      };
    },
    [setMotor]: (state, action) => {
      return {
        ...state,
        motorSelected: action.payload.value,
      };
    },
    [setNumeroChasis]: (state, action) => {
      return {
        ...state,
        numeroChasisSelected: action.payload.value,
      };
    },
    [setValidadorDatosPago]: (state, action) => {
      return {
        ...state,
        validadorDatosPagoSelected: action.payload.value,
      };
    },
    [setMarcaTarjetaCredito]: (state, action) => {
      return {
        ...state,
        marcaTarjetaCreditoSelected: action.payload.value,
      };
    },
    [setTarjetaDeMercantil]: (state, action) => {
      return {
        ...state,
        tarjetaDeMercantil: action.payload.value,
      };
    },
    [setFechaVencimientoTarjeta]: (state, action) => {
      return {
        ...state,
        fechaVencimientoTarjetaSelected: action.payload.value,
      };
    },

    [setOmitirCargaFotos]: (state, action) => {
      return {
        ...state,
        fotoFrenteVehiculoSelected: action.payload.value,
        fotoTraseraVehiculoSelected: action.payload.value,
        fotoLateralIzquierdoVehiculoSelected: action.payload.value,
        fotoLateralDerechoVehiculoSelected: action.payload.value,
      };
    },
    [setFotoFrenteVehiculo]: (state, action) => {
      return {
        ...state,
        fotoFrenteVehiculoSelected: action.payload.value,
      };
    },
    [setFotoTraseraVehiculo]: (state, action) => {
      return {
        ...state,
        fotoTraseraVehiculoSelected: action.payload.value,
      };
    },
    [setFotoLateralIzquierdoVehiculo]: (state, action) => {
      return {
        ...state,
        fotoLateralIzquierdoVehiculoSelected: action.payload.value,
      };
    },
    [setFotoLateralDerechoVehiculo]: (state, action) => {
      return {
        ...state,
        fotoLateralDerechoVehiculoSelected: action.payload.value,
      };
    },
    [setInitialCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.value,
      };
    },
    [setCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.value,
      };
    },
    [setContratarCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.value,
      };
    },
    [setVigenciaDesde]: (state, action) => {
      return {
        ...state,
        fechaVigenciaDesdeSelected: action.payload.value,
      };
    },

    //CONTINUAR MULTICOTIZACIONES
    [postRecotizarInited]: (state, action) => {
      return {
        ...state,
        sendPostCotizacion: false,
      };
    },
    [postRecotizarCompleted]: (state, action) => {
      return {
        ...state,
        multicotizacionInitialStage: stages.recotizar,
        sendPostCotizacion: false,
      };
    },
    [fetchRecotizarFailed]: (state, action) => {
      return {
        ...state,
        multicotizacionInitialStage: stages.initialStage,
        sendPostCotizacion: false,
      };
    },
    //CONTINUAR MULTICOTIZACIONES
    [fetchContinuarSolicitudInited]: (state, action) => {
      return {
        ...state,
        cotizacionARecotizar: null,
      };
    },
    [fetchContinuarSolicitudCompleted]: (state, action) => {
      const data = action.payload.response;
      return {
        ...state,
        multicotizacionInitialStage: stages.continuarSolicitud,
        agenciaSelected: {
          value: data.agenciaId,
          label: data.agencia,
          selected: true,
        },
        marcaSelected: { value: data.marca, label: data.marca },
        modeloSelected: { value: data.codia, label: data.modelo },
        anioSelected: { value: data.anio, label: data.anio },
        codigoPostalSelected: data.codigoPostal,
        usoSelected: { value: data.uso, label: data.uso },
        tieneGNC: { value: data.tieneGNC, selected: true },
        formaDePagoSelected: { value: data.formaPago, label: data.formaPago },
        cotizacionARecotizar: data.cotizacionARecotizar,
        cotizacionId: data.cotizacionId,

        currentPoliza: {
          value: data.currentPoliza,
          open: true,
          contratada: true,
        },
      };
    },
    [fetchContinuarSolicitudFailed]: (state, action) => {
      return {
        ...state,
        multicotizacionInitialStage: stages.initialStage,
      };
    },
    //GET MULTICOTIZACIONES
    [getDetalleCotizacionInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getDetalleCotizacionCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        detalleCotizacion: action.payload.detalleCotizacion,
        polizasRequestCount: state.polizasRequestCount + 1,
      };
    },
    [getDetalleCotizacionFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    //POST MULTICOTIZACIONES
    [postPedidoCotizacionInited]: (state, action) => {
      return {
        ...state,
        cotizacionId: null,
        detalleCotizacion: null,
        polizasRequestCount: 0,
        isLoading: true,
      };
    },
    [postPedidoCotizacionCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        cotizacionId: action.payload.response,
        sendPostCotizacion: false,
      };
    },
    [postPedidoCotizacionFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        sendPostCotizacion: false,
      };
    },

    //POST DATOS CONTACTO
    [postDatosContactoInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        postDatosContactoError: null,
      };
    },
    [postDatosContactoCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        sendPostSolicitudEmision: false,
      };
    },
    [postDatosContactoFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        sendPostSolicitudEmision: false,
        postDatosContactoError: true,
      };
    },
    //GET IDENTIDAD
    [fetchIdentidadInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        nombreSelected: null,
        apellidoSelected: null,
        fechaNacimientoSelected: { value: null, selected: false },
        cuilSelected: { value: null, selected: false },
      };
    },
    [fetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreSelected: action.payload.response.nombre,
        apellidoSelected: action.payload.response.apellido,
        fechaNacimientoSelected: {
          value: action.payload.response.fechaNacimiento,
          selected: true,
        },
        cuilSelected: {
          value: action.payload.response.cuil,
          selected: true,
        },
      };
    },
    [fetchIdentidadFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreSelected: null,
        apellidoSelected: null,
        fechaNacimientoSelected: {
          value: null,
          selected: false,
        },
      };
    },
  },
  initialState
);

export default multicotizadorReducer;
export {
  clear,
  clearAgencia,
  setAgencia,
  clearVehiculoMarca,
  setVehiculoMarca,
  clearVehiculoAnio,
  setVehiculoAnio,
  clearVehiculoModelo,
  setVehiculoModelo,
  clearProvincia,
  setProvincia,
  clearLocalidad,
  setLocalidad,
  clearCodigoPostal,
  setCodigoPostal,
  clearVehiculoUso,
  setVehiculoUso,
  clearCondicionFiscal,
  setCondicionFiscal,
  clearTieneGNC,
  setTieneGNC,
  clearFormaDePago,
  setFormaDePago,
  clearDni,
  setDni,
  clearSexo,
  setSexo,
  clearEsPersonaValida,
  setEsPersonaValida,
  clearFechaNacimiento,
  setFechaNacimiento,
  clearNombre,
  setNombre,
  clearApellido,
  setApellido,
  clearCuil,
  setCuil,
  clearDireccion,
  setDireccion,
  clearTelefono,
  setTelefono,
  clearEmail,
  setEmail,
  clearDominio,
  setDominio,
  clearMotor,
  setMotor,
  clearNumeroChasis,
  setNumeroChasis,
  clearValidadorDatosPago,
  setValidadorDatosPago,
  clearMarcaTarjetaCredito,
  setMarcaTarjetaCredito,
  clearTarjetaDeMercantil,
  setTarjetaDeMercantil,
  clearFechaVencimientoTarjeta,
  setFechaVencimientoTarjeta,
  setInitialCurrentPoliza,
  clearCurrentPoliza,
  setCurrentPoliza,
  clearContratarCurrentPoliza,
  setContratarCurrentPoliza,
  clearFotoFrenteVehiculo,
  setFotoFrenteVehiculo,
  clearFotoTraseraVehiculo,
  setFotoTraseraVehiculo,
  clearFotoLateralIzquierdoVehiculo,
  setFotoLateralIzquierdoVehiculo,
  clearFotoLateralDerechoVehiculo,
  setFotoLateralDerechoVehiculo,
  clearVigenciaDesde,
  setVigenciaDesde,
  clearOmitirCargaFotos,
  setOmitirCargaFotos,
};

export const recotizar = (id) => {
  return async (dispatch, getState, services) => {
    dispatch(postRecotizarInited());
    try {
      const response = await services.api.multicotizador().postRecotizacion(id);
      dispatch(postRecotizarCompleted());
      dispatch(postPedidoCotizacionCompleted(response.cotizacionId));
      dispatch(getDetalleCotizacion(response.cotizacionId));
    } catch (error) {
      console.error(error);
      dispatch(fetchRecotizarFailed(error));
    }
  };
};

export const continuarSolicitud = (id, currentPoliza) => {
  return async (dispatch, getState, services) => {
    dispatch(fetchContinuarSolicitudInited());
    try {
      const response = await services.api
        .cotizaciones()
        .getDetalleCotizacion(id);

      dispatch(
        fetchContinuarSolicitudCompleted({
          ...response,
          cotizacionARecotizar: id,
          currentPoliza,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(fetchContinuarSolicitudFailed(error));
    }
  };
};

export const fetchIdentidad = (documento, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchIdentidadInited());
      const response = await services.api
        .identidad()
        .getIdentidad(documento, sexo);
      const nombre = Capitalize(response.nombres);
      const apellido = Capitalize(response.apellido);
      const fechaNacimiento = new Date(response.fechaNacimiento).toUTCString();

      const cuil = response.cuil;
      dispatch(
        fetchIdentidadCompleted({
          nombre,
          apellido,
          fechaNacimiento,
          cuil,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(fetchIdentidadFailed(error));
    }
  };
};

export const postPedidoCotizacion = ({
  agenciaSelected,
  anioSelected,
  modeloSelected,
  localidadSelected,
  provinciaSelected,
  codigoPostalSelected,
  usoSelected,
  condicionFiscalSelected,
  tieneGNC,
  formaDePagoSelected,
  cotizacionARecotizar,
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postPedidoCotizacionInited());

      const data = {
        AgenciaId: agenciaSelected.value,
        ModeloVehiculo: modeloSelected.value,
        AnioVehiculo: anioSelected.value,
        CodigoPostal: codigoPostalSelected,
        Localidad: localidadSelected,
        ProvinciaId: provinciaSelected,
        Negocio: "MercadoAbierto",
        UsoDelVehiculo: usoSelected.value,
        CondicionFiscal: condicionFiscalSelected
          ? condicionFiscalSelected.value
          : null,
        TieneGNC: tieneGNC.value,
        Periodo: "Anual",
        FormaPago: formaDePagoSelected.value,
        CotizacionARecotizar: cotizacionARecotizar,
      };

      const response = await services.api.multicotizador().postCotizacion(data);

      let cotizacionId = null;

      if (
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0
      ) {
        cotizacionId = response.cotizacionId;
      } else {
        throw new Error("fail on postCotizacion");
      }

      dispatch(postPedidoCotizacionCompleted(cotizacionId));

      dispatch(getDetalleCotizacion(cotizacionId));
    } catch (error) {
      console.error(error);
      dispatch(postPedidoCotizacionFailed(error));
    }
  };
};

export const getDetalleCotizacion = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDetalleCotizacionInited());

      const response = await services.api
        .cotizaciones()
        .getDetalleCotizacion(id);

      dispatch(getDetalleCotizacionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetalleCotizacionFailed(error));
    }
  };
};

export const postDatosContacto = ({
  cotizacionId,
  nombreSelected,
  apellidoSelected,
  dniSelected,
  telefonoSelected,
  currentPoliza,
  direccionSelected,
  fechaNacimientoSelected,
  cuilSelected,
  sexoSelected,
  emailSelected,
  dominioSelected,
  motorSelected,
  numeroChasisSelected,
  validadorDatosPagoSelected,
  fotoFrenteVehiculoSelected,
  fotoTraseraVehiculoSelected,
  fotoLateralIzquierdoVehiculoSelected,
  fotoLateralDerechoVehiculoSelected,
  fechaVigenciaDesdeSelected,
  marcaTarjetaCreditoSelected,
  fechaVencimientoTarjetaSelected,
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postDatosContactoInited());

      const fotosUploaded =
        fotoFrenteVehiculoSelected.value &&
        fotoTraseraVehiculoSelected.value &&
        fotoLateralIzquierdoVehiculoSelected.value &&
        fotoLateralDerechoVehiculoSelected.value;

      const fotos = [];

      if (fotosUploaded) {
        const fotoFrente = (
          await fileService("temp").download(fotoFrenteVehiculoSelected.value)
        ).url;

        const fotoTrasera = (
          await fileService("temp").download(fotoTraseraVehiculoSelected.value)
        ).url;

        const fotoLateralIzquierdo = (
          await fileService("temp").download(
            fotoLateralIzquierdoVehiculoSelected.value
          )
        ).url;

        const fotoLateralDerecho = (
          await fileService("temp").download(
            fotoLateralDerechoVehiculoSelected.value
          )
        ).url;

        fotos.push(fotoFrente);
        fotos.push(fotoTrasera);
        fotos.push(fotoLateralIzquierdo);
        fotos.push(fotoLateralDerecho);
      }

      const data = {
        MulticotizacionId: cotizacionId,
        CoberturaSeleccionadaId: currentPoliza.value.cotizacionPlanId,
        Nombre: nombreSelected,
        Apellido: apellidoSelected,
        DNI: dniSelected,
        Email: emailSelected.value,
        Telefono: telefonoSelected.value,
        EsTelefonoMovil: telefonoSelected.isMobile,
        Direccion: direccionSelected,
        FechaNacimiento: fechaNacimientoSelected.value,
        CUIL: cuilSelected.value,
        Sexo: sexoSelected.value,
        Dominio: dominioSelected.value,
        NumeroMotor: motorSelected.value,
        NumeroChasis: numeroChasisSelected.value,
        DatosPago: validadorDatosPagoSelected.value,
        UrlFotosVehiculo: fotos,
        VigenciaDesde: fechaVigenciaDesdeSelected.value,
        MarcaTarjetaCredito: marcaTarjetaCreditoSelected.value,
        FechaVencimientoTarjetaCredito: fechaVencimientoTarjetaSelected.value,
      };

      const response = await services.api
        .multicotizador()
        .postSolicitudEmision(data);

      dispatch(postDatosContactoCompleted(response));
    } catch (error) {
      console.error("Error during postDatosContacto:", error.message || error);
      dispatch(postDatosContactoFailed(error));
    }
  };
};

//private functions
const upperCase = (str) => {
  return str.toUpperCase();
};
const Capitalize = (str) => {
  str = str.toLowerCase();
  var firstLetterRx = /(^|\s)[a-z]/g;
  return str.replace(firstLetterRx, upperCase);
};
