import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const getDetalleSolicitudEmisionInited = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_INITED"
);
const getDetalleSolicitudEmisionCompleted = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_COMPLETED",
  (response) => {
    let detalleSolicitudEmision = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      detalleSolicitudEmision = response;
    }

    return { detalleSolicitudEmision };
  }
);
const getDetalleSolicitudEmisionFailed = createAction(
  "OBTENER_DETALLE_SOLICITUDEMISION_FAILED",
  (error) => ({
    error,
  })
);

const getDetalleSolicitudEmision = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDetalleSolicitudEmisionInited());

      const response = await services.api
        .solicitudesEmision()
        .getDetalleSolicitudEmision(id);

      dispatch(getDetalleSolicitudEmisionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetalleSolicitudEmisionFailed(error));
    }
  };
};

const patchSolicitudEmisionInited = createAction(
  "UPDATE_SOLICITUD_EMISION_INITED"
);
const patchSolicitudEmisionCompleted = createAction(
  "UPDATE_SOLICITUD_EMISION_COMPLETED",
  (response) => { }
);
const patchSolicitudEmisionFailed = createAction(
  "UPDATE_SOLICITUD_EMISION_FAILED",
  (error) => ({
    error,
  })
);

const patchSolicitudEmision = (solicitudEmisionId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchSolicitudEmisionInited());

      const response = await services.api
        .solicitudesEmision()
        .patchSolicitudEmision(solicitudEmisionId, data);

      dispatch(patchSolicitudEmisionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(patchSolicitudEmisionFailed(error));
      dispatch(
        queueMessage("Ocurrió un error al crear la póliza. Intente nuevamente.")
      );
    }
  };
};

const getDatosPagoInited = createAction("OBTENER_DATOS_PAGO_INITED");
const getDatosPagoCompleted = createAction(
  "OBTENER_DATOS_PAGO_COMPLETED",
  (response) => {
    let datosPago = "";
    let fechaVencimientoTarjetaCredito = "";

    if (typeof response !== "undefined" && response !== null) {
      datosPago = response.datoDePago;
      fechaVencimientoTarjetaCredito = response.fechaVencimientoTarjetaCredito;
    }

    return { datosPago, fechaVencimientoTarjetaCredito };
  }
);
const getDatosPagoFailed = createAction(
  "OBTENER_DATOS_PAGO_FAILED",
  (error) => ({
    error,
  })
);

const setEmail = createAction("SET_EMAIL", (emailSelected) => ({
  emailSelected,
}));
const setDominio = createAction("SET_DOMINIO", (dominioSelected) => ({
  dominioSelected,
}));
const setVigenciaDesde = createAction(
  "SET_VIGENCIA_DESDE",
  (vigenciaDesdeSelected) => ({
    vigenciaDesdeSelected,
  })
);
const setNroMotor = createAction("SET_NRO_MOTOR", (nroMotorSelected) => ({
  nroMotorSelected,
}));
const setNroChasis = createAction("SET_NRO_CHASIS", (nroChasisSelected) => ({
  nroChasisSelected,
}));

const getDatosPago = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDatosPagoInited());

      const response = await services.api.solicitudesEmision().getDatosPago(id);

      dispatch(getDatosPagoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDatosPagoFailed(error));
    }
  };
};

const patchRechazarSolicitudInited = createAction("RECHAZAR_SOLICITUD_INITED");
const patchRechazarSolicitudCompleted = createAction(
  "RECHAZAR_SOLICITUD_COMPLETED",
  (response) => { }
);
const patchRechazarSolicitudFailed = createAction(
  "RECHAZAR_SOLICITUD_FAILED",
  (error) => ({
    error,
  })
);

const patchRechazarSolicitud = (solicitudEmisionId, data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(patchRechazarSolicitudInited());

      const response = await services.api
        .solicitudesEmision()
        .patchRechazarSolicitud(solicitudEmisionId, data);

      dispatch(patchRechazarSolicitudCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(patchRechazarSolicitudFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al rechazar la solicitud. Intente nuevamente."
        )
      );
    }
  };
};

const clearDatosPago = createAction("CLEAR_DATOS_PAGO", (datosPago) => ({
  datosPago,
}));

const clearData = createAction("CLEAR_DATA", () => ({}));

const setSubmitting = createAction("SET_SUBMITTING", (submitting) => ({
  submitting,
}));

const setFotoFrontalFileName = createAction(
  "SET_FOTO_FRONTAL_FILENAME",
  (fotoFrontalFileNameSelected) => ({
    fotoFrontalFileNameSelected,
  })
);

const setFotoTraseraFileName = createAction(
  "SET_FOTO_TRASERA_FILENAME",
  (fotoTraseraFileNameSelected) => ({
    fotoTraseraFileNameSelected,
  })
);

const setFotoLateralIzquierdoFileName = createAction(
  "SET_FOTO_LATERAL_IZQUIERDO_FILENAME",
  (fotoLateralIzquierdoFileNameSelected) => ({
    fotoLateralIzquierdoFileNameSelected,
  })
);

const setFotoLateralDerechoFileName = createAction(
  "SET_FOTO_LATERAL_DERECHO_FILENAME",
  (fotoLateralDerechoFileNameSelected) => ({
    fotoLateralDerechoFileNameSelected,
  })
);

const detalleSolicitudEmisionInitialState = {
  detalleSolicitudEmision: null,
  isLoading: true,
  polizaCreada: false,
  creatingPoliza: false,
  datosPago: null,
  emailSelected: { value: "", valid: false, focus: false },
  dominioSelected: { value: "", valid: false, focus: false },
  vigenciaDesdeSelected: { value: new Date(), valid: true, focus: false },
  nroMotorSelected: { value: "", valid: false, focus: false },
  nroChasisSelected: { value: "", valid: false, focus: false },
  submitting: false,
  loadingError: false,
  fotoFrontalFileNameSelected: { value: null, valid: false, focus: false },
  fotoTraseraFileNameSelected: { value: null, valid: false, focus: false },
  fotoLateralIzquierdoFileNameSelected: {
    value: null,
    valid: false,
    focus: false,
  },
  fotoLateralDerechoFileNameSelected: {
    value: null,
    valid: false,
    focus: false,
  },
};

const detalleSolicitudPendienteSucursalReducer = handleActions(
  {
    [getDetalleSolicitudEmisionInited]: (state, action) => {
      return {
        ...detalleSolicitudEmisionInitialState,
      };
    },
    [getDetalleSolicitudEmisionCompleted]: (state, action) => {
      return {
        ...state,
        detalleSolicitudEmision: action.payload.detalleSolicitudEmision,
        isLoading: false,
      };
    },
    [getDetalleSolicitudEmisionFailed]: (state, action) => {
      return {
        ...state,
        loadingError: true,
        errorMessage: action.payload.error.responseStatus.message,
      };
    },
    [patchSolicitudEmisionInited]: (state, action) => {
      return {
        ...state,
        creatingPoliza: true,
        isLoading: true,
      };
    },
    [patchSolicitudEmisionCompleted]: (state, action) => {
      return {
        ...state,
        polizaCreada: true,
        creatingPoliza: false,
        isLoading: false,
      };
    },
    [patchSolicitudEmisionFailed]: (state, action) => {
      return {
        ...state,
        creatingPoliza: false,
        isLoading: false,
      };
    },
    [patchRechazarSolicitudInited]: (state, action) => {
      return {
        ...state,
      };
    },
    [patchRechazarSolicitudCompleted]: (state, action) => {
      return {
        ...state,
        solicitudRechazada: true,
      };
    },
    [patchRechazarSolicitudFailed]: (state, action) => {
      return {
        ...state,
      };
    },
    [getDatosPagoInited]: (state, action) => {
      return {
        ...state,
      };
    },
    [getDatosPagoCompleted]: (state, action) => {
      let datosPago = action.payload.datosPago;
      let fechaVencimientoTarjetaCredito = action.payload.fechaVencimientoTarjetaCredito;
      return {
        ...state,
        datosPago: { datosPago, fechaVencimientoTarjetaCredito },
      };
    },
    [getDatosPagoFailed]: (state, action) => {
      return {
        ...state,
      };
    },
    [clearDatosPago]: (state, action) => {
      return {
        ...state,
        datosPago: null,
      };
    },
    [clearData]: (state, action) => {
      return {
        ...detalleSolicitudEmisionInitialState,
      };
    },
    [setEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: action.payload.emailSelected,
      };
    },
    [setDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: action.payload.dominioSelected,
      };
    },
    [setVigenciaDesde]: (state, action) => {
      return {
        ...state,
        vigenciaDesdeSelected: action.payload.vigenciaDesdeSelected,
      };
    },
    [setNroMotor]: (state, action) => {
      return {
        ...state,
        nroMotorSelected: action.payload.nroMotorSelected,
      };
    },
    [setNroChasis]: (state, action) => {
      return {
        ...state,
        nroChasisSelected: action.payload.nroChasisSelected,
      };
    },
    [setSubmitting]: (state, action) => {
      return {
        ...state,
        submitting: action.payload.submitting,
        emailSelected: { ...state.emailSelected, focus: false },
        dominioSelected: { ...state.dominioSelected, focus: false },
        nroMotorSelected: { ...state.nroMotorSelected, focus: false },
        nroChasisSelected: { ...state.nroChasisSelected, focus: false },
        vigenciaDesdeSelected: { ...state.vigenciaDesdeSelected, focus: false },
      };
    },
    [setFotoFrontalFileName]: (state, action) => {
      return {
        ...state,
        fotoFrontalFileNameSelected: action.payload.fotoFrontalFileNameSelected,
      };
    },
    [setFotoTraseraFileName]: (state, action) => {
      return {
        ...state,
        fotoTraseraFileNameSelected: action.payload.fotoTraseraFileNameSelected,
      };
    },
    [setFotoLateralIzquierdoFileName]: (state, action) => {
      return {
        ...state,
        fotoLateralIzquierdoFileNameSelected:
          action.payload.fotoLateralIzquierdoFileNameSelected,
      };
    },
    [setFotoLateralDerechoFileName]: (state, action) => {
      return {
        ...state,
        fotoLateralDerechoFileNameSelected:
          action.payload.fotoLateralDerechoFileNameSelected,
      };
    },
  },
  detalleSolicitudEmisionInitialState
);

export {
  detalleSolicitudPendienteSucursalReducer,
  getDetalleSolicitudEmision,
  patchSolicitudEmision,
  patchRechazarSolicitud,
  getDatosPago,
  clearDatosPago,
  setEmail,
  setDominio,
  setNroChasis,
  setNroMotor,
  setVigenciaDesde,
  clearData,
  setSubmitting,
  setFotoFrontalFileName,
  setFotoTraseraFileName,
  setFotoLateralDerechoFileName,
  setFotoLateralIzquierdoFileName,
};
