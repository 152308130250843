import React, { useEffect } from "react";
import ContentSection from "~components/styled/contentSection/ContentSection";
import { clear } from "../actions";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";

import { useDispatch, useSelector } from "react-redux";
import pxToRem from "~libs/pxToRem";
import Typography from "@material-ui/core/Typography";
import IconCheck from "~assets/icons/icon-check-big.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import GenericError from "../errores/GenericError";

const useReduxSelector = () =>
  useSelector((state) => ({
    currentState: state.multicotizadorReducer,
  }));

const FinalStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentState } = useReduxSelector();
  const { postDatosContactoError } = currentState;

  useEffect(() => {
    return function cleanup() {
      dispatch(clear());
    };
  }, [dispatch]);

  const downloadingCertificado = true;
  const disable = true;
  return (
    <>
      {postDatosContactoError && <GenericError />}
      {!postDatosContactoError && (
        <ContentSection>
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                marginTop: 40,
              }}
              width={"250"}
              alt=""
              src={IconCheck}
            />
            <Typography
              style={{
                marginTop: 61,
                fontSize: pxToRem(24),
                fontSeight: "bold",
                lineHeight: 1.25,
                textAlign: "center",
                color: "#663ea8",
              }}
            >
              Solicitud de Emisión enviada
            </Typography>
            <Typography
              style={{
                fontSize: pxToRem(16),
                lineHeight: 1.38,
                textAlign: "center",
                color: "#616161",
                marginLeft: "10%",
                marginRight: "10%",
                marginTop: 22,
                marginBottom: 42,
              }}
            >
              La solicitud de emisión ha sido enviada con éxito.
            </Typography>
            {!disable && (
              <>
                <CallToActionButton
                  //disabled={notaCancelacionDownloading}
                  variant="outlined"
                  color="secondary"
                  label={
                    downloadingCertificado ? (
                      <CircularProgress
                        size={18}
                        thickness={4}
                        style={{
                          alignSelf: "center",
                          marginRight: 5,
                          color: "#bdbdbd",
                        }}
                      />
                    ) : (
                      "Descargar certificado"
                    )
                  }
                  marginBottom={12}
                  onClick={() => {
                    //dispatch(getNotaCancelacion(operacionId));
                  }}
                />
              </>
            )}

            <CallToActionButton
              variant="outlined"
              color="secondary"
              label={"Volver al Inicio"}
              marginBottom={12}
              onClick={() => {
                history.push("/");
              }}
            />
          </div>
        </ContentSection>
      )}
    </>
  );
};

export default FinalStep;
