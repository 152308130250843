import { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import DialogContentText from "@material-ui/core/DialogContentText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/styles/makeStyles";
import { Tooltip, IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ContentLoader from "react-content-loader";
import pxToRem from "~libs/pxToRem";
import getMarcasTarjetaCredito from "~components/business/marcaTarjetaCreditoSelector/getMarcasTarjetaCredito";

const useStyles = makeStyles((theme) => ({
  logoSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "6%",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  vtoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "5px",
    width: "100%",
  },
  hidden: {
    height: "0px",
    width: "0px",
    opacity: 0,
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
    marginLeft: "6%",
  },
  dialog: { minWidth: pxToRem(300) },
}));

const DatosPagoDialog = ({
  isLoading,
  openDialog,
  onClose,
  datosPago,
  formaPago,
  marcaTC,
}) => {
  const [open, setOpen] = useState(openDialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleCopyToClipboard = () => {
    reference.current.select();
    document.execCommand("copy");
  };

  let reference = useRef();

  useEffect(() => {
    setOpen(openDialog);

    if (openDialog) {
      setTimeout(handleClose, 15000); // we display data for only 15 seconds
    }
    //eslint-disable-next-line
  }, [openDialog]);

  const marcaTCElement = getMarcasTarjetaCredito().find(
    (element) => element.value === marcaTC
  );

  let title =
    formaPago === "DebitoConTarjetaCredito"
      ? `Tarjeta de crédito ${marcaTCElement ? marcaTCElement.label : ""}`
      : formaPago === "DebitoConCBU"
        ? "CBU"
        : null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="simple-dialog-title" className={classes.dialog}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        {!isLoading && (
          <DialogContentText
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Box>
              <div className={classes.logoContainer}>
                <textarea
                  className={classes.hidden}
                  ref={reference}
                  value={datosPago?.datosPago || ""}
                />
                {datosPago?.datosPago || ""}
                <Tooltip title={"Copiar al portapapeles"}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleCopyToClipboard}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {
                formaPago === "DebitoConTarjetaCredito" &&
                <div className={classes.vtoContainer}>
                  Vto: {datosPago?.fechaVencimientoTarjetaCredito || ""}
                </div>
              }
            </Box>
          </DialogContentText>
        )}

        {isLoading && (
          <div style={{ width: "100%", height: "100%", padding: pxToRem(4) }}>
            <ContentLoader
              height={20}
              width={"100%"}
              speed={1}
              backgroundColor="#f7f8ff"
              foregroundColor="#d9e8df"
            >
              <rect x="35" y="0" rx="4" ry="4" width="80%" height="20" />
            </ContentLoader>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DatosPagoDialog;
