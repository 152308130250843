const getMarcasTarjetaCredito = () => {
  let result = [
    {
      label: "Visa",
      value: "Visa",
    },
    {
      label: "MasterCard",
      value: "MasterCard",
    },
    {
      label: "American Express",
      value: "AmericanExpress",
    },
    {
      label: "Tarjeta Naranja",
      value: "TarjetaNaranja",
    },
    {
      label: "Argencard",
      value: "Argencard",
    },
    {
      label: "Cabal",
      value: "Cabal",
    },
    {
      label: "Cencosud",
      value: "Cencosud",
    },
    {
      label: "Cencosud Mastercard",
      value: "CencosudMastercard",
    },
    {
      label: "Centrocard",
      value: "Centrocard",
    },
    {
      label: "Cliper",
      value: "Cliper",
    },
    {
      label: "Cordobesa",
      value: "Cordobesa",
    },
    {
      label: "Credimas",
      value: "Credimas",
    },
    {
      label: "Diners",
      value: "Diners",
    },
    {
      label: "Elebar",
      value: "Elebar",
    },
    {
      label: "Favacard",
      value: "Favacard",
    },
    {
      label: "Galicia Rural",
      value: "GaliciaRural",
    },
    {
      label: "Kadicard",
      value: "Kadicard",
    },
    {
      label: "Nativa",
      value: "Nativa",
    },
    {
      label: "Nevada",
      value: "Nevada",
    },
    {
      label: "Patagonia",
      value: "Patagonia",
    },
    {
      label: "Tarjeta Plata",
      value: "TarjetaPlata",
    },
  ];

  return result;
};

export default getMarcasTarjetaCredito;
